// React Imports
import React from "react";

// Style Imports
import "../assets/scss/index.scss";

const SectionBanner = props => {
    return (
        <section className="section__banner">
            <div className="container">
                <div className="section__border mb-32"></div>
                <h1 className="section__heading mb-0">Ben Fowler:</h1>
                <p className="section__text" dangerouslySetInnerHTML={{ __html: "Animator, <br>Creative Developer &<br>Game Designer." }}></p>
            </div>
            <canvas id="hero__background"></canvas>
            <div className="section__banner--loader"></div>
        </section>
    );
};

const Project = props => {
    return (  
        <div className="mt-n2">
            <a href={props.link} target="_blank" className="section__projects--link">
                <div className="section__border"></div>
                <div className="d-flex section__projects--link-container">
                    <p className="section__text project">{props.project}</p> 
                    <p className="section__text role mr-auto">{props.role}</p> 
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className="my-auto mr-16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.65342 4.96473e-05L14.4706 4.8737e-05C14.8931 -0.00332594 15.2751 0.16878 15.5523 0.4455C15.8295 0.722219 16.0003 1.10372 16 1.52521V12.324C16 13.1677 15.3155 13.851 14.4704 13.851C13.6253 13.851 12.9408 13.1677 12.9408 12.324L12.9426 5.21048L2.5717 15.5638C1.97372 16.1608 1.03601 16.1298 0.469121 15.5638C-0.0977647 14.9979 -0.18971 14.001 0.408275 13.4041L10.7792 3.05071L3.65342 3.05071C2.80833 3.05071 2.1238 2.36735 2.1238 1.5237C2.1238 0.680038 2.80833 4.97184e-05 3.65342 4.96473e-05Z" fill="#232323"/>
                    </svg>
                </div>
                <div className="section__border"></div>
            </a>
      </div>
    );
};

const SectionProjects = props => {
    return (
        <section className="section__projects">
            <div className="container">
                <h2 className="section__heading mb-32">Selected Projects:</h2>
                <div className="d-flex">
                    <p className="section__text fw-700 mb-16 mr-auto w-lg-50">Project</p> 
                    <p className="section__text fw-700 mb-16 w-lg-50">Role</p> 
                </div>
                <Project 
                    link="https://oshstudio.itch.io/deaths-gambit"
                    project="Deaths Gambit"
                    role="Game Jammer"
                />
                <Project 
                    link="https://www.behance.net/gallery/129211983/Oiyo-Logo-Design-and-Animation"
                    project="Oiyo"
                    role="Lead Developer & Animator"
                />
                <Project 
                    link="https://www.behance.net/gallery/129159859/Iterate-Agency-Launch-Campaign-Video"
                    project="Iterate Agency"
                    role="Freelancer"
                />
                <Project 
                    link="https://www.behance.net/gallery/129160381/Little-Print-Social-Animations"
                    project="Little Print"
                    role="Freelancer"
                />
                <Project 
                    link="https://www.behance.net/gallery/129227803/Khang-Khodos-Business-Cards"
                    project="Khang & Khodos"
                    role="Freelancer"
                />
            </div>
        </section>
    );
};

const SectionCurrently = props => {
    return (
        <section className="section__currently">
            <div className="container">
                <h2 className="section__heading mb-32">Currently:</h2>
                <div className="d-flex flex-column flex-lg-row">
                    <div className="order-lg-2 w-lg-40 offset-lg-20">
                        <p className="section__text mb-16">
                            <span>Listening to </span> 
                            <a className="section__link" 
                            href="https://open.spotify.com/track/5PILoHCrMiK6zwkGdeMOXX?si=93606775857b421d" 
                            target="_blank">Desert Sun</a>
                            <span> by </span>
                            <a className="section__link"
                            href="https://open.spotify.com/artist/107Qkrj4miJTEjTZx8Ie3c?si=a24754add2d34640" 
                            target="_blank">Nabes</a>
                        </p>
                        <p className="section__text mb-16">
                            <span>Watching </span>
                            <a className="section__link"
                            href="https://en.wikipedia.org/wiki/Primal_(TV_series)" 
                            target="_blank">Primal Season 2</a>
                            <span> directed by </span>
                            <a className="section__link"
                            href="https://en.wikipedia.org/wiki/Genndy_Tartakovsky" 
                            target="_blank">Genndy Tartakovsky</a>
                        </p>
                        <p className="section__text mb-16">
                            <span>Reading </span>
                            <a className="section__link"
                            href="https://en.wikipedia.org/wiki/Berserk_(manga)" 
                            target="_blank">Berserk</a>
                            <span> written by </span>
                            
                            <a className="section__link"
                            href="https://en.wikipedia.org/wiki/Kentaro_Miura" 
                            target="_blank">Kentaro Miura (RIP)</a>
                        </p>
                        <p className="section__text mb-16">
                            <span>Recommending </span> 
                            <a className="section__link"
                            href="https://www.imdb.com/title/tt3915174/" 
                            target="_blank">Puss in Boots: The Last Wish</a>
                            <span> by </span> 
                            <a className="section__link"
                            href="https://en.wikipedia.org/wiki/DreamWorks_Animation" 
                            target="_blank">DreamWorks</a>
                        </p>
                    </div>
                    <div className="order-lg-1 w-lg-30">
                        <p className="section__text mb-16" dangerouslySetInnerHTML={{ __html: "I am working as a freelance digital creative in Brisbane, Australia, whilst studying Animation & VFX." }}></p>
                        <p className="section__text mb-16" dangerouslySetInnerHTML={{ __html: "I am available to be hired for any projects that need Web Design & Development, Graphic Design, Animation, or Game Design & Development." }}></p>
                        <p className="section__text" dangerouslySetInnerHTML={{ __html: "Chasing my dreams!!!" }}></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionContact = props => {
    return (
        <section className="section__contact">
            <div className="container">
                <div className="section__border mb-32"></div>
                <div className="d-flex flex-column flex-lg-row">
                    <div className="mb-32 w-lg-40 mb-lg-0">
                        <h2 className="section__heading mb-32">Let’s Collaborate:</h2>
                        <p className="section__text" dangerouslySetInnerHTML={{ __html: "Do you want to hire me for a project?" }}></p>
                        <p className="section__text" dangerouslySetInnerHTML={{ __html: "Have you got a cool idea you want to talk about?" }}></p>
                        <p className="section__text mb-32" dangerouslySetInnerHTML={{ __html: "Just want a chat?" }}></p>
                        <p className="section__text" dangerouslySetInnerHTML={{ __html: "Email me:" }}></p>
                        <div className="d-flex mb-16">
                            <div className="d-flex section__link--svg mr-16" onClick={() => {navigator.clipboard.writeText("ben.r.fowler@gmail.com"); showForShortTime(document.querySelector("#email-copy"));}} >
                                <svg className="mr-16 my-auto" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 3V0H8.5C7.67156 0 7 0.671562 7 1.5V10.5C7 11.3284 7.67156 12 8.5 12H14.5C15.3284 12 16 11.3284 16 10.5V4H13.0281C12.45 4 12 3.55 12 3ZM13 0V3H16L13 0ZM6 11V4H1.5C0.671562 4 0 4.67156 0 5.5V14.5C0 15.3284 0.671562 16 1.5 16H7.5C8.32844 16 9 15.3284 9 14.5V13H8C6.89687 13 6 12.1031 6 11Z"/>
                                </svg>
                                <div className="section__text ">ben.r.fowler@gmail.com</div>
                            </div>
                            <div id="email-copy" className="section__text color-green my-auto mr-auto d-none">copied!</div>
                        </div>
                        <p className="section__text mb-16" dangerouslySetInnerHTML={{ __html: "Otherwise, if you’re just interested in my journey you can follow me here:" }}></p>
                        <div className="d-flex">
                            <a href="https://www.behance.net/bennyfowler" target="_blank" className="section__link--svg d-flex">
                                <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.875 11.1187C12.3656 10.4062 13.1437 9.32811 13.1437 7.64999C13.1437 4.34061 10.6781 3.53436 7.83281 3.53436H0V20.1469H8.05313C11.0719 20.1469 13.9078 18.6984 13.9078 15.3234C13.9078 13.2375 12.9187 11.6953 10.875 11.1187ZM3.65156 6.3703H7.07812C8.39531 6.3703 9.58125 6.74061 9.58125 8.26874C9.58125 9.67968 8.65781 10.2469 7.35469 10.2469H3.65156V6.3703ZM7.55625 17.325H3.65156V12.75H7.63125C9.23906 12.75 10.2563 13.4203 10.2563 15.1219C10.2563 16.8 9.04219 17.325 7.55625 17.325ZM24.3609 6.04217H17.625V4.40624H24.3609V6.04217ZM27 14.3062C27 10.7484 24.9188 7.78124 21.1453 7.78124C17.4797 7.78124 14.9906 10.5375 14.9906 14.1469C14.9906 17.8922 17.3484 20.4609 21.1453 20.4609C24.0188 20.4609 25.8797 19.1672 26.775 16.4156H23.8594C23.5453 17.4422 22.2516 17.9859 21.2484 17.9859C19.3125 17.9859 18.2953 16.8516 18.2953 14.925H26.9719C26.9859 14.7281 27 14.5172 27 14.3062ZM18.3 12.8437C18.4078 11.2641 19.4578 10.275 21.0422 10.275C22.7016 10.275 23.5359 11.25 23.6766 12.8437H18.3Z"/>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/in/ben-fowler-746a8815b/" target="_blank" className="section__link--svg d-flex">
                                <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 1.5H1.49531C0.670312 1.5 0 2.17969 0 3.01406V20.9859C0 21.8203 0.670312 22.5 1.49531 22.5H19.5C20.325 22.5 21 21.8203 21 20.9859V3.01406C21 2.17969 20.325 1.5 19.5 1.5ZM6.34687 19.5H3.23438V9.47812H6.35156V19.5H6.34687ZM4.79062 8.10938C3.79219 8.10938 2.98594 7.29844 2.98594 6.30469C2.98594 5.31094 3.79219 4.5 4.79062 4.5C5.78437 4.5 6.59531 5.31094 6.59531 6.30469C6.59531 7.30312 5.78906 8.10938 4.79062 8.10938ZM18.0141 19.5H14.9016V14.625C14.9016 13.4625 14.8781 11.9672 13.2844 11.9672C11.6625 11.9672 11.4141 13.2328 11.4141 14.5406V19.5H8.30156V9.47812H11.2875V10.8469H11.3297C11.7469 10.0594 12.7641 9.22969 14.2781 9.22969C17.4281 9.22969 18.0141 11.3062 18.0141 14.0062V19.5Z"/>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/bennyfowler/" target="_blank" className="section__link--svg d-flex">
                                <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5047 6.60937C7.52344 6.60937 5.11875 9.01406 5.11875 11.9953C5.11875 14.9766 7.52344 17.3812 10.5047 17.3812C13.4859 17.3812 15.8906 14.9766 15.8906 11.9953C15.8906 9.01406 13.4859 6.60937 10.5047 6.60937ZM10.5047 15.4969C8.57813 15.4969 7.00312 13.9266 7.00312 11.9953C7.00312 10.0641 8.57344 8.49374 10.5047 8.49374C12.4359 8.49374 14.0062 10.0641 14.0062 11.9953C14.0062 13.9266 12.4313 15.4969 10.5047 15.4969ZM17.3672 6.38906C17.3672 7.08749 16.8047 7.64531 16.1109 7.64531C15.4125 7.64531 14.8547 7.08281 14.8547 6.38906C14.8547 5.69531 15.4172 5.13281 16.1109 5.13281C16.8047 5.13281 17.3672 5.69531 17.3672 6.38906ZM20.9344 7.66406C20.8547 5.98124 20.4703 4.49062 19.2375 3.26249C18.0094 2.03437 16.5188 1.64999 14.8359 1.56562C13.1016 1.46718 7.90313 1.46718 6.16875 1.56562C4.49062 1.64531 3 2.02968 1.76719 3.25781C0.534375 4.48593 0.154687 5.97656 0.0703124 7.65937C-0.0281251 9.39374 -0.0281251 14.5922 0.0703124 16.3266C0.15 18.0094 0.534375 19.5 1.76719 20.7281C3 21.9562 4.48594 22.3406 6.16875 22.425C7.90313 22.5234 13.1016 22.5234 14.8359 22.425C16.5188 22.3453 18.0094 21.9609 19.2375 20.7281C20.4656 19.5 20.85 18.0094 20.9344 16.3266C21.0328 14.5922 21.0328 9.39843 20.9344 7.66406ZM18.6937 18.1875C18.3281 19.1062 17.6203 19.8141 16.6969 20.1844C15.3141 20.7328 12.0328 20.6062 10.5047 20.6062C8.97656 20.6062 5.69062 20.7281 4.3125 20.1844C3.39375 19.8187 2.68594 19.1109 2.31562 18.1875C1.76719 16.8047 1.89375 13.5234 1.89375 11.9953C1.89375 10.4672 1.77188 7.18124 2.31562 5.80312C2.68125 4.88437 3.38906 4.17656 4.3125 3.80624C5.69531 3.25781 8.97656 3.38437 10.5047 3.38437C12.0328 3.38437 15.3188 3.26249 16.6969 3.80624C17.6156 4.17187 18.3234 4.87968 18.6937 5.80312C19.2422 7.18593 19.1156 10.4672 19.1156 11.9953C19.1156 13.5234 19.2422 16.8094 18.6937 18.1875Z"/>
                                </svg>
                            </a>
                            <a href="https://osh.studio/" target="_blank" className="section__link--svg d-flex">
                                <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.3888 10.3359L18.8634 11.2688L18.8592 13.6576L25.3842 12.7247L25.3712 19.8899L16.6709 21.1341L16.6751 18.7454L23.2006 17.8124L23.2047 15.4237L16.6798 16.3571L16.6927 9.19142L25.393 7.94769L25.3888 10.3359Z"/>
                                    <path d="M7.99244 10.4357L7.98829 12.8245L5.81348 13.1353L5.79586 22.6892L14.4961 21.445L14.5179 9.50278L7.99244 10.4357ZM12.3338 14.5906L12.3296 16.9788L12.3255 19.367L10.1507 19.6778L7.97586 19.9886L7.98 17.6004L7.98415 15.2122L7.98829 12.8239L10.1631 12.5131L12.3379 12.2023L12.3338 14.5906Z"/>
                                    <path d="M34.0844 11.4805L29.7343 12.1026L29.7431 7.32563L27.5678 7.63643L27.546 19.5791L29.7214 19.2678L29.7302 14.4908L34.0803 13.8687L34.0715 18.6457L36.2468 18.3349L36.2686 6.3927L34.0933 6.7035L34.0844 11.4805Z"/>
                                    <path d="M36.2727 4.00392L32.6522 2L2.20124 6.35435L2.19709 8.74257L0.0217636 9.0539L0 20.9961L3.62054 23L3.6423 11.0578L5.81763 10.7465L5.82177 8.35775L36.2727 4.00392Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="w-lg-40 offset-lg-10">
                        <h2 className="section__heading mb-32">Hello World:</h2>
                        <p className="section__text mb-16" dangerouslySetInnerHTML={{ __html: "In 2018 I completed a Bachelor of Multimedia Design and joined the workforce as a Front-End Designer / Developer, designing and building many small to medium sized websites. The multi-disciplinary nature of this job is what shaped the ‘all-rounder’ style I utilize to this day." }}></p>
                        <p className="section__text mb-16">
                            I ended up putting my hand up for some animation work and started to realize that it was something I wanted to pursue. Fast forward a few years and I took up a new challenge with the lovely people at <a className="section__link" href="https://iterateagency.com/" target="_blank">Iterate Agency</a>. The culture there was amazing, and they really encouraged learning. I greatly improved my skillset while there.
                        </p>
                        <p className="section__text mb-16">
                            During my time there I also found joy in learning game development, and how games were made. I tested myself by participating in <a className="section__link" href="https://ldjam.com/" target="_blank">Ludum Dare</a>, a game jam that gives you 48-72 hours to develop a game from scratch. You can find my games <a className="section__link" href="https://oshstudio.itch.io/" target="_blank">here.</a>
                        </p>
                        <p className="section__text">
                        In 2022 I finished my journey at Iterate Agency and started a second degree studying Animation & VFX. Now, I am focusing on building my own brand, <a className="section__link" href="https://osh.studio/" target="_blank">Osh Studio</a>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

const Footer = props => {
    return (
        <footer>
            <div className="container">
                <div className="section__border mb-32"></div>
                <div className="d-flex justify-content-between flex-column flex-lg-row">
                    <div className="d-flex order-lg-2">
                        <div className="section__link coming-soon mb-16 mr-32">The Cloud Gallery</div>
                        <div className="section__link coming-soon mb-16">Blog</div>
                    </div>
                    <p className="section__text mr-auto order-lg-1 mb-16">© Osh Studio 2022. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

const Home = () => (
    <>
        <SectionBanner />
        <SectionProjects />
        <SectionCurrently />
        <SectionContact />
        <Footer />
    </>
);

export default Home;

function showForShortTime(element) {
    element.classList.remove('d-none')
    setTimeout(function(){ element.classList.add('d-none') }, 3000);
}
